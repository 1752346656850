<template>
  <div class="drawer-container">
    <!-- <BackNav /> -->
    <div class="grid grid-cols-2 gap-4">
      <Card
        class="card drawer-2 relative"
        @click="$router.push('/drawer/card')"
      >
        <p
          class="text-grey flex items-center justify-between absolute top-3 right-3"
        >
          <span
            class="font-semibold px-3 py-1 text-teal-400 bg-teal-300 rounded-lg text-xs ml-2"
            >Beta</span
          >
        </p>
        <div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="21"
            height="16"
            fill="none"
          >
            <path
              d="M18.5 0h-16C1.39 0 .51.89.51 2L.5 14c0 1.11.89 2 2 2h16c1.11 0 2-.89 2-2V2c0-1.11-.89-2-2-2Zm-1 14h-14c-.55 0-1-.45-1-1V8h16v5c0 .55-.45 1-1 1Zm1-10h-16V2h16v2Z"
              fill="#6956C8"
            />
          </svg>
          <p class="leading-6 text-dark text-lg mt-2">
            Zilla <br />
            cards
          </p>
        </div>
      </Card>
      <Card class="card drawer-1" @click="$router.push('/drawer/calculator')">
        <div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="16"
            fill="none"
          >
            <path
              d="M13 12h3c.55 0 1-.45 1-1V5c0-.55-.45-1-1-1h-3c-.55 0-1 .45-1 1v6c0 .55.45 1 1 1Zm1-6h1v4h-1V6Zm-7 6h3c.55 0 1-.45 1-1V5c0-.55-.45-1-1-1H7c-.55 0-1 .45-1 1v6c0 .55.45 1 1 1Zm1-6h1v4H8V6ZM4 4c-.55 0-1 .45-1 1v6c0 .55.45 1 1 1s1-.45 1-1V5c0-.55-.45-1-1-1ZM0 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V2c0-1.1-.9-2-2-2H2C.9 0 0 .9 0 2Zm17 12H3c-.55 0-1-.45-1-1V3c0-.55.45-1 1-1h14c.55 0 1 .45 1 1v10c0 .55-.45 1-1 1Z"
              fill="#10DF88"
            />
          </svg>
          <p class="leading-6 text-dark text-lg mt-2">
            Cost <br />
            Calculator
          </p>
        </div>
      </Card>
    </div>
    <!-- <div class="grid grid-cols-2 gap-4 mt-4">
      <Card class="card drawer-3" @click="showCheckout = true">
        <div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="25"
            height="24"
            fill="none"
          >
            <path
              d="M.5 8c0-4.41828 3.58172-8 8-8h8c4.4183 0 8 3.58172 8 8v8c0 4.4183-3.5817 8-8 8h-8c-4.41828 0-8-3.5817-8-8V8Z"
              fill="#fff"
            />
            <path
              d="M12.5 9c.55 0 1-.45 1-1V6h2c.55 0 1-.45 1-1s-.45-1-1-1h-2V2c0-.55-.45-1-1-1s-1 .45-1 1v2h-2c-.55 0-1 .45-1 1s.45 1 1 1h2v2c0 .55.45 1 1 1Zm-5 9c-1.1 0-1.99.9-1.99 2s.89 2 1.99 2 2-.9 2-2-.9-2-2-2Zm10 0c-1.1 0-1.99.9-1.99 2s.89 2 1.99 2 2-.9 2-2-.9-2-2-2Zm-8.9-5h7.45c.75 0 1.41-.41 1.75-1.03l3.38-6.13c.27-.48.09-1.09-.39-1.36-.48-.26-1.09-.09-1.35.39L16.05 11H9.03L5.04 2.57c-.16-.35-.52-.57-.9-.57H2.5c-.55 0-1 .45-1 1s.45 1 1 1h1l3.6 7.59-1.35 2.44C5.02 15.37 5.98 17 7.5 17h11c.55 0 1-.45 1-1s-.45-1-1-1h-11l1.1-2Z"
              fill="#62DFDF"
            />
          </svg>
          <p class="leading-6 text-dark text-lg mt-2">
            Make <br />
            purchase
          </p>
        </div>
      </Card>
      <Card
        disabled
        class="card drawer-4"
        @click="$router.push('/drawer/card/spending-limit')"
      >
        <div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="none"
          >
            <path
              d="M0 8c0-4.41828 3.58172-8 8-8h8c4.4183 0 8 3.58172 8 8v8c0 4.4183-3.5817 8-8 8H8c-4.41828 0-8-3.5817-8-8V8Z"
              fill="#fff"
            />
            <path
              d="m16.8505 6.85 1.44 1.44-4.88 4.88-3.29-3.29c-.39003-.39-1.02003-.39-1.41003 0l-6 6.01c-.39.39-.39 1.02 0 1.41.39.39 1.02.39 1.41 0l5.29-5.3 3.29003 3.29c.39.39 1.02.39 1.41 0l5.59-5.58 1.44 1.44c.31.31.85.09.85-.35V6.5c.01-.28-.21-.5-.49-.5h-4.29c-.45 0-.67.54-.36.85Z"
              fill="#FFC130"
            />
          </svg>
          <p class="leading-6 text-dark text-lg mt-2">
            Spending<br />
            limit
          </p>
        </div>
      </Card>
    </div> -->
    <Card class="w-full card bottom-card mt-4">
      <div>
        <p class="text-dark font-semibold">Shop with zilla merchants</p>
      </div>
      <SecondaryButton text="View categories" @click="showCategories = true" />
    </Card>
    <Checkout
      :display="showCheckout"
      @close="showCheckout = false"
      @success="showCheckout = false"
    />
    <LargeModal
      background="#f3f3f6"
      :display="showCategories"
      @close="showCategories = false"
    >
      <MerchantCategory
        :loading="dashboardLoading"
        :dashboardData="dashboardData.businessCategories"
      />
    </LargeModal>
    <!-- modal for credit card calculatoe -->
    <!-- <LargeModal :display="showCreditCardCalculator"  @close="showCreditCardCalculator = false">
      <div class="lg:w-9/12 mx-auto">
        <CreditCardCalculator/>
      </div>
    </LargeModal> -->
  </div>
</template>
<script>
import { mapState, mapActions } from "vuex";
import { BackNav } from "@/UI/Navigation";
import { SecondaryButton } from "@/UI/Button";
import { LargeModal } from "@/UI/Modals";

export default {
  components: {
    BackNav,
    SecondaryButton,
    Checkout: () => import("@/components/Home/Checkout/Checkout.vue"),
    MerchantCategory: () => import("@/components/Home/MerchantCategory"),
    CreditCardCalculator:() => import('../ZillaCards/Calculator/Calculator.vue'),
    LargeModal
  },
  data() {
    return {
      showCheckout: false,
      showCategories: false,
      dashboardLoading: false,
      showCreditCardCalculator: false,
    };
  },
  computed: {
    ...mapState({
      dashboardData: (state) => state?.dashboard?.dashboardData,
    }),
  },
  beforeMount() {
    this.allDashboardData();
  },
  methods: {
    ...mapActions("dashboard", ["getDashboardData"]),
    allDashboardData() {
      this.dashboardLoading = true;
      this.getDashboardData()
        .then(() => {
          this.dashboardLoading = false;
        })
        .catch(() => {
          this.dashboardLoading = false;
        });
    },
  },
};
</script>
<style scoped>
.drawer-container {
  max-width: 36.563rem;
  margin: auto;
}
.card {
  /* height: 7.75rem; */
  height: 10rem;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.bottom-card {
  justify-content: space-between;
}
.drawer-1 {
  background-image: url("https://res.cloudinary.com/zillaafrica/image/upload/v1659960119/customer/pattern_4_bhomke.svg");
  background-position-x: center;
  background-repeat: no-repeat;
}
.drawer-2 {
  background-image: url("https://res.cloudinary.com/zillaafrica/image/upload/v1659960119/customer/pattern_1_q6yaxr.svg");
  background-position-x: center;
  background-repeat: no-repeat;
}
.drawer-3 {
  background-image: url("https://res.cloudinary.com/zillaafrica/image/upload/v1659960539/customer/pattern_2_g1ixew.svg");
  background-position-x: center;
  background-repeat: no-repeat;
}
.drawer-4 {
  background-image: url("https://res.cloudinary.com/zillaafrica/image/upload/v1659960539/customer/pattern_3_ixzc3u.svg");
  background-position-x: right;
  background-repeat: no-repeat;
}
</style>
