<template>
  <Drawer />
</template>
<script>
  import Drawer from "@/components/Drawer/Drawer";
  export default {
    components: {
      Drawer,
    },
  };
</script>
