var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "drawer-container" },
    [
      _c(
        "div",
        { staticClass: "grid grid-cols-2 gap-4" },
        [
          _c(
            "Card",
            {
              staticClass: "card drawer-2 relative",
              on: {
                click: function ($event) {
                  return _vm.$router.push("/drawer/card")
                },
              },
            },
            [
              _c(
                "p",
                {
                  staticClass:
                    "text-grey flex items-center justify-between absolute top-3 right-3",
                },
                [
                  _c(
                    "span",
                    {
                      staticClass:
                        "font-semibold px-3 py-1 text-teal-400 bg-teal-300 rounded-lg text-xs ml-2",
                    },
                    [_vm._v("Beta")]
                  ),
                ]
              ),
              _c("div", [
                _c(
                  "svg",
                  {
                    attrs: {
                      xmlns: "http://www.w3.org/2000/svg",
                      width: "21",
                      height: "16",
                      fill: "none",
                    },
                  },
                  [
                    _c("path", {
                      attrs: {
                        d: "M18.5 0h-16C1.39 0 .51.89.51 2L.5 14c0 1.11.89 2 2 2h16c1.11 0 2-.89 2-2V2c0-1.11-.89-2-2-2Zm-1 14h-14c-.55 0-1-.45-1-1V8h16v5c0 .55-.45 1-1 1Zm1-10h-16V2h16v2Z",
                        fill: "#6956C8",
                      },
                    }),
                  ]
                ),
                _c("p", { staticClass: "leading-6 text-dark text-lg mt-2" }, [
                  _vm._v(" Zilla "),
                  _c("br"),
                  _vm._v(" cards "),
                ]),
              ]),
            ]
          ),
          _c(
            "Card",
            {
              staticClass: "card drawer-1",
              on: {
                click: function ($event) {
                  return _vm.$router.push("/drawer/calculator")
                },
              },
            },
            [
              _c("div", [
                _c(
                  "svg",
                  {
                    attrs: {
                      xmlns: "http://www.w3.org/2000/svg",
                      width: "20",
                      height: "16",
                      fill: "none",
                    },
                  },
                  [
                    _c("path", {
                      attrs: {
                        d: "M13 12h3c.55 0 1-.45 1-1V5c0-.55-.45-1-1-1h-3c-.55 0-1 .45-1 1v6c0 .55.45 1 1 1Zm1-6h1v4h-1V6Zm-7 6h3c.55 0 1-.45 1-1V5c0-.55-.45-1-1-1H7c-.55 0-1 .45-1 1v6c0 .55.45 1 1 1Zm1-6h1v4H8V6ZM4 4c-.55 0-1 .45-1 1v6c0 .55.45 1 1 1s1-.45 1-1V5c0-.55-.45-1-1-1ZM0 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V2c0-1.1-.9-2-2-2H2C.9 0 0 .9 0 2Zm17 12H3c-.55 0-1-.45-1-1V3c0-.55.45-1 1-1h14c.55 0 1 .45 1 1v10c0 .55-.45 1-1 1Z",
                        fill: "#10DF88",
                      },
                    }),
                  ]
                ),
                _c("p", { staticClass: "leading-6 text-dark text-lg mt-2" }, [
                  _vm._v(" Cost "),
                  _c("br"),
                  _vm._v(" Calculator "),
                ]),
              ]),
            ]
          ),
        ],
        1
      ),
      _c(
        "Card",
        { staticClass: "w-full card bottom-card mt-4" },
        [
          _c("div", [
            _c("p", { staticClass: "text-dark font-semibold" }, [
              _vm._v("Shop with zilla merchants"),
            ]),
          ]),
          _c("SecondaryButton", {
            attrs: { text: "View categories" },
            on: {
              click: function ($event) {
                _vm.showCategories = true
              },
            },
          }),
        ],
        1
      ),
      _c("Checkout", {
        attrs: { display: _vm.showCheckout },
        on: {
          close: function ($event) {
            _vm.showCheckout = false
          },
          success: function ($event) {
            _vm.showCheckout = false
          },
        },
      }),
      _c(
        "LargeModal",
        {
          attrs: { background: "#f3f3f6", display: _vm.showCategories },
          on: {
            close: function ($event) {
              _vm.showCategories = false
            },
          },
        },
        [
          _c("MerchantCategory", {
            attrs: {
              loading: _vm.dashboardLoading,
              dashboardData: _vm.dashboardData.businessCategories,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }